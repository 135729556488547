.some-header {
    color: darkgrey;
}

.loader {
    border: 10px solid #f3f3f3;
    border-radius: 50%;
    border-top: 10px solid darkgrey;
    border-bottom: 10px solid darkgrey;
    width: 60px;
    height: 60px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;

    z-index: 11;
    position: fixed;
    top: 50%;
    left: 50%;
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}
.button {
    margin-bottom: 0;
}

.loader {

}
.hoverDiv:hover {
    background: #f5f5f5;
    cursor: pointer;
}
body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

